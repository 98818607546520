import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import { Icon } from '../icon/icon'

export const StyledCardContainer = styled(Grid)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);

  ${props => props.theme.breakpoints.up('sm')} {
    border-bottom: none;
    margin-bottom: ${props => props.theme.typography.pxToRem(80)};
  }
`

export const StyledTitleText = styled.a`
  color: ${props => props.theme.palette.text.primary};
  font-size: ${props => props.theme.typography.pxToRem(20)};
  font-weight: 500;
  text-decoration: none;
  margin: ${props => props.theme.typography.pxToRem(32)} 0 0 0;
  transition: margin-right 0.2s ease, color 0.2s ease;

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(20)};
    margin-left: 0;
    margin-top: 0;
  }

  &:hover {
    margin-right: ${props => props.theme.typography.pxToRem(20)};
    color: ${props => props.theme.palette.secondary.dark};
  }
`

export const StyledTitleCheveron = styled(Icon)<{$rotate?: boolean}>`
  color: ${props => props.theme.palette.primary.main};
  transform: ${props => props.$rotate ? 'rotate(180deg)' : 'none'};
  margin-top: ${props => props.theme.typography.pxToRem(4)};
  font-size: ${props => props.theme.typography.pxToRem(14)};
  transition: transform 0.2s ease;

  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: ${props => props.theme.typography.pxToRem(10)};
    font-size: ${props => props.theme.typography.pxToRem(15)};
    margin-left: ${props => props.theme.typography.pxToRem(9)};
  }
`

export const StyledQuickLink = styled(Grid)`
  min-width: ${props => props.theme.typography.pxToRem(180)};

  ${props => props.theme.breakpoints.up('md')} {
    min-width: ${props => props.theme.typography.pxToRem(210)};
  }
`
export const StyledTitleGridWrapper = styled(Grid)`
  padding-left: ${props => props.theme.typography.pxToRem(30)};
`

export const StyledChevronGridWrapper = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const StyledCardIconContainer = styled(Grid)`
  margin-bottom: ${props => props.theme.typography.pxToRem(26)};
`

export const StyledCardList = styled(Grid)`
  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: ${props => props.theme.typography.pxToRem(20)};
  }
`
